.service-center-pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.service-center-pagination__page-item .service-center-pagination__page-link {
  position: relative;
  display: block;
  margin: 0 5px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: #502080;
  text-decoration: none;
}

.service-center-pagination__page-item a.service-center-pagination__page-link:hover {
  background-color: #cccccc;
}

.service-center-pagination__page-item--active .service-center-pagination__page-link {
  font-weight: 700;
  color: #ffffff;
  background-color: #502080;
}

.service-center-pagination__page-item--disabled .service-center-pagination__page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.service-center-pagination__sr {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
