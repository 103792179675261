.ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection) {
  border: 1px solid #4951EF;
  background-image: none;
}

.ReactCrop__drag-handle {
  background-color: #4951EF;
  border-radius: 100%;
  border: 0;
}

.ReactCrop__crop-mask > rect {
  fill: black;
  fill-opacity: 0.4;
}